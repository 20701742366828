
import AreaManagerForm from '@/components/Settings/AreaManager/Form.vue';
export default {
  components: { AreaManagerForm },
  computed: {
    areaManagerId() {
      return this.$route.query.id as string;
    }
  }
};
